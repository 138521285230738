import Api from '../../utils/API';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { putAccessRules } from '../../actions';
import { getAccessRules } from '../../selectors/accessRules';

export const accessRulesSaga = function* (): SagaIterator {
  try {
    const accessRules = yield select(getAccessRules);
    yield put(putAccessRules({ ...accessRules, isLoadingAccessRules: true }));

    const { result: availableRules } = yield call(Api.AccessRules.get);
    const { result: planConfig } = yield call(Api.AccessRules.getPlanConfig);
    const { result: planUsage } = yield call(Api.AccessRules.getPlanUsage);

    planConfig.videoStorageMb = Math.max(planConfig.videoStorageMb || 0, 0);

    yield put(
      putAccessRules({ availableRules, planConfig, planUsage, isLoadingAccessRules: false })
    );
  } catch (e) {
    console.error('Failed to track event', e);
  }
};
